import React from 'react'

import './Footer.scss';

const Footer = () => {
  return 
  
  (
    <div>Footer</div>
  )
}



export default Footer